import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ExportType } from '@enum';
import { SurveyFilter } from '@interfaces';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReportsService } from '../../services/reports.service';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'app/core/services/file.service';
import { ModalService } from 'app/core/services/modal.service';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrl: './surveys.component.scss',
})
export class SurveysComponent {
  @ViewChild('surveyDate', { static: true }) surveyDate!: TemplateRef<any>;
  @ViewChild('question1', { static: true }) question1!: TemplateRef<any>;
  @ViewChild('question2', { static: true }) question2!: TemplateRef<any>;
  @ViewChild('question3', { static: true }) question3!: TemplateRef<any>;
  @ViewChild('question4', { static: true }) question4!: TemplateRef<any>;
  @ViewChild('question5', { static: true }) question5!: TemplateRef<any>;
  @ViewChild('surveyDetails', { static: true })
  surveyDetails!: TemplateRef<any>;
  @ViewChild('surveyDetailsModal', { static: true })
  surveyDetailsModal!: TemplateRef<any>;

  @ViewChild('surveyTable') table: any;

  filters: SurveyFilter;
  estatus: string = 'all';
  columns: any[] = [];
  surveyList: any[] = [];
  itemCount: number = 0;
  isLoading: boolean = false;
  selectedSurvey!: any;
  modalRef!: BsModalRef;
  exportType = ExportType;

  constructor(
    private reportsService: ReportsService,
    private toastr: ToastrService,
    private fileService: FileService,
    private modalService: ModalService
  ) {
    this.filters = {
      page: 0,
      number: '',
      end_date: undefined,
      start_date: undefined,
      user_name: '',
    };
  }

  messages = {
    emptyMessage: 'Sin resultados',
    totalMessage: 'Total',
    selectedMessage: 'Seleccionado',
  };

  async ngOnInit() {
    this.initTableConfig();
    this.getSurveys();
  }

  getSurveys = async (): Promise<any> => {
    this.isLoading = true;
    this.reportsService
      .filteredSurveys({ ...this.filters })
      .subscribe(({ data, count, code }): any => {
        if (code === 500) {
          this.isLoading = false;
          return this.toastr.error(
            'No se han podido obtener las encuestas',
            '¡Error!'
          );
        }
        this.itemCount = count;
        this.isLoading = false;
        this.surveyList = data;
        return;
      });
  };

  export = async (format: ExportType): Promise<any> => {
    this.reportsService
      .exportSurveys(format, this.filters)
      .subscribe(({ code, data }: any) => {
        if (code === 500) {
          return this.toastr.error('No se pudo generar el archivo', '¡Error!');
        }
        this.toastr.success('¡Archivo descargado!', '¡Éxito!');
        this.fileService.downloadFile(data.url, data.name);
        return;
      });
  };

  public setPage = async (pageInfo: any): Promise<any> => {
    this.filters.page = pageInfo.offset;
    this.getSurveys();
  };

  initTableConfig = (): void => {
    this.columns = [
      {
        name: 'Nombre del<br>cliente',
        prop: 'customer.complete_name',
        resizeable: false,
        draggable: false,
      },
      {
        name: 'Folio de pedido',
        prop: 'number',
        resizeable: false,
        draggable: false,
      },
      {
        name: 'Fecha',
        prop: 'surveyDate',
        resizeable: false,
        draggable: false,
        cellTemplate: this.surveyDate,
      },
      {
        name: 'Tipo de servicio',
        prop: 'type_product',
        resizeable: false,
        draggable: false,
        sortable: false,
      },
      {
        name: 'Tiempo de<br>atención<br>oportuno',
        resizeable: false,
        draggable: false,
        sortable: false,
        cellTemplate: this.question1,
      },
      {
        name: 'Calidad de<br>servicio<br>adecuada',
        resizeable: false,
        draggable: false,
        sortable: false,
        cellTemplate: this.question2,
      },
      {
        name: 'Atención<br>telefónica<br>de calidad',
        resizeable: false,
        draggable: false,
        sortable: false,
        cellTemplate: this.question3,
      },
      {
        name: 'Calidad en el<br>trato del<br>vendedor',
        resizeable: false,
        draggable: false,
        sortable: false,
        cellTemplate: this.question4,
      },
      {
        name: 'Experiencia<br>satisfactoria',
        resizeable: false,
        draggable: false,
        sortable: false,
        cellTemplate: this.question5,
      },
      {
        name: 'Ver Respuestas',
        resizeable: false,
        draggable: false,
        sortable: false,
        cellTemplate: this.surveyDetails,
      },
    ];
  };

  getQuestionMessage(survey: any, index: number): string {
    if (survey.poll_answers.length === 0) return 'Sin respuesta';
    if (survey.poll_answers[index].answer) return 'Si';
    return 'No';
  }

  getSurveyDate(survey: any): string {
    if (survey.poll_answers.length > 0)
      return survey.poll_answers[0].created_at;
    return '---';
  }

  openDetailsModal(survey: any) {
    this.selectedSurvey = survey;
    this.modalRef = this.modalService.openModal(
      this.surveyDetailsModal,
      'modal-lg modal-rounded'
    );
  }

  resetFilters() {
    this.filters = {
      number: '',
      page: 0,
      end_date: undefined,
      start_date: undefined,
    };
  }

  filter() {
    this.filters.page = 0;
    this.getSurveys();
  }

  onSort(event: any) {
    this.table.offset = this.filters.page;
    const sortDirection = event.sorts[0].dir; // Dirección del ordenamiento ('asc' o 'desc')
    const sortProp = event.sorts[0].prop; // Propiedad sobre la que se está ordenando
    console.log('sortProp', sortProp);
    // Ordenar por surveyDate;
    if (sortProp === 'surveyDate') {
      this.surveyList.sort((a, b) => {
        const dateA = new Date(this.getSurveyDate(a)).getTime();
        const dateB = new Date(this.getSurveyDate(b)).getTime();

        if (sortDirection === 'asc') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
      this.surveyList = [...this.surveyList];
    }
  }
}
