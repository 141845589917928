import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { ThemeService } from 'app/core/services/theme.service';
import { UserService } from 'app/features/users/services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  @Input() title: string = '';
  userData: any;
  imageSrc: string = '';
  isDarkMode: boolean = false;
  switchMode: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private themeService: ThemeService,
    private userService: UserService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.authService.user$.subscribe((user) => {
      this.userData = user;
      this.imageSrc = this.userData.path_profile;
    });

    this.themeService.darkTheme$.subscribe((isDark) => {
      this.isDarkMode = isDark;
    });
    this.switchMode = this.userData.dark_mode == 0 ? false : true;
    this.toggleTheme(this.switchMode, false);
  }

  logout = (): void => {
    this.authService.removeSession();
    this.router.navigate(['/login']);
  };

  toggleTheme(isDarkMode: boolean, isUpdated: boolean) {
    if (isUpdated) {
      this.toggleDarkMode(isDarkMode);
    }
    this.themeService.setDarkTheme(isDarkMode);
  }

  async toggleDarkMode(isDarkMode: boolean) {
    this.userData.dark_mode = isDarkMode ? 1 : 0;
    await this.userService
      .toggleDarkMode(this.userData.dark_mode, this.userData.id)
      .subscribe(({ data, code }) => {
        if (code === 500) {
          return this.toastr.error(
            'No fue posible actualizar el modo de visualización',
            '¡Error!'
          );
        }
        this.authService.setSession(data);
        this.toastr.success(`¡Tema actualizado!`, '¡Éxito!');
        return;
      });
  }

  expandScreen() {
    const elem = document.documentElement;

    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err) => {
        console.error(
          `Error al intentar activar el modo pantalla completa: ${err.message}`
        );
      });
    } else {
      document.exitFullscreen().catch((err) => {
        console.error(
          `Error al intentar salir del modo pantalla completa: ${err.message}`
        );
      });
    }
  }
}
