import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { Login } from '@interfaces';
import { AuthService } from 'app/core/auth/auth.service';
import {
  noSpacesValidator,
  passwordContainsLetterAndNumber,
} from '@validators';
import { ThemeService } from 'app/core/services/theme.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  submitted: boolean = false;
  loginForm: FormGroup;
  isVisible: boolean = false;
  isDarkMode: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService,
    private themeService: ThemeService
  ) {
    this.loginForm = this.fb.group({
      number: ['', [Validators.required, Validators.maxLength(5)]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
          noSpacesValidator(),
          passwordContainsLetterAndNumber(),
        ],
      ],
    });
  }

  ngOnInit() {
    this.themeService.darkTheme$.subscribe((isDark) => {
      this.isDarkMode = isDark;
    });

    const savedTheme = localStorage.getItem('$theme');
    if (savedTheme) {
      this.toggleTheme(savedTheme === 'dark');
    }
  }

  toggleTheme(isDarkMode: boolean) {
    this.themeService.setDarkTheme(isDarkMode);
  }

  public onSubmit = async (): Promise<void> => {
    this.submitted = true;
    if (this.loginForm.valid) {
      const loginData: Login = this.loginForm.value;
      await this.authService
        .login(loginData)
        .subscribe(({ data, code, token }) => {
          if (code === 500) {
            this.submitted = false;
            return this.toastr.error(
              'Credenciales incorrectas, inténtalo de nuevo',
              '¡Error!'
            );
          }
          data.token = token;
          this.authService.setSession(data);
          this.toastr.success(`¡Bienvenido ${data?.complete_name}!`, '¡Éxito!');
          if (this.authService.isFinance()) {
            this.router.navigate(['/orders']);
            return;
          }
          this.router.navigate(['/dashboard']);
          return;
        });
    }
  };

  get passwordErrors() {
    const passwordControl = this.loginForm.get('password');
    if (passwordControl?.hasError('required')) {
      return 'Contraseña requerida.';
    } else if (passwordControl?.hasError('minlength')) {
      return 'Debe tener mínimo 8 caracteres.';
    } else if (passwordControl?.hasError('maxlength')) {
      return 'Debe tener máximo 20 caracteres.';
    } else if (passwordControl?.hasError('containsLetterAndNumber')) {
      return 'Debe contener letras y números.';
    } else if (passwordControl?.hasError('noSpaces')) {
      return 'No puede contener espacios.';
    }
    return '';
  }

  get employeeErrors() {
    const employeeControl = this.loginForm.get('number');
    if (employeeControl?.hasError('required')) {
      return 'Número de empleado requerido.';
    } else if (employeeControl?.hasError('maxlength')) {
      return 'Debe contener máximo 5 caracteres.';
    }
    return '';
  }

  changePasswordVisibility = (): void => {
    this.isVisible = !this.isVisible;
  };
}
