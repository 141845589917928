import { environment } from '@env/environment';
import { CONTROLLER } from './controllers';

const apiUrl = environment.apiUrl;
const authUrl = environment.authUrl;

const usersUrl = `${apiUrl}${CONTROLLER.USER}`;
const pricingtUrl = `${apiUrl}${CONTROLLER.PRICES}`;
const addressesUrl = `${apiUrl}${CONTROLLER.ADRESSES}`;
const fiscalDataUrl = `${apiUrl}${CONTROLLER.FISCAL_DATA}`;
const ordersUrl = `${apiUrl}${CONTROLLER.ORDERS}`;
const reportsUrl = `${apiUrl}${CONTROLLER.REPORTS}`;

export const ENDPOINT = {
  AUTH: {
    LOGIN: `${authUrl}/login`,
    SEND_RECOVER_EMAIL: `${apiUrl}/sendEmailV2`,
    VERIFY_RESET_TOKEN: `${apiUrl}/verifyResetToken`,
    CHANGE_FORGOTTEN_PASSWORD: `${apiUrl}/changePassword`,
    SEND_RECOVER_EMAIL_BY_EMPLOYEE: `${apiUrl}/sendEmailByNumber`,
  },
  USER: {
    CHANGE_PASSWORD: `${usersUrl}/changePassword`,
    UPDATE_USER: (id: number) => `${usersUrl}/${id}`,
    SET_PROFILE_PICTURE: (id: number) => `${usersUrl}/profilepicture/${id}`,
    PAGINATED: (
      page: number,
      type_user: string,
      number?: string,
      name?: string,
      state?: string
    ) => buildPaginatedUsersUrl(page, type_user, number, name, state),
    EXPORT: (type_user: string, export_type: string, filters: any) =>
      buildExportUsersUrl(type_user, export_type, filters),
    STORE: usersUrl,
    CHANGE_STATUS: (id: number) => `${usersUrl}/toggleUserStatus/${id}`,
    UPDATE_ADDRESS: (id: number) => `${addressesUrl}/${id}`,
    UPDATE_FISCAL_DATA: `${fiscalDataUrl}/updateFiscalData`,
    GET_RECORD: (name: string) => `${fiscalDataUrl}/getRecord/${name}`,
    TOOGLE_THEME: (id: number) => `${usersUrl}/toggleDarkMode/${id}`,
  },
  PRICES: {
    STORE: `${pricingtUrl}`,
    UPDATE: (id: number) => `${pricingtUrl}/${id}`,
    EXPORT: (type: string) => `${pricingtUrl}/${type}`,
    PAGINATED: (
      page: number,
      startDate?: string,
      endDate?: string,
      product?: string
    ) => buildPaginatedPricesUrl(page, startDate, endDate, product),
    LATEST: `${pricingtUrl}/last`,
  },
  ORDERS: {
    PAGINATED: (
      size: number,
      page: number,
      statusNew: boolean,
      statusCanceled: boolean,
      statusInProgress: boolean,
      statusDone: boolean,
      number?: string,
      userId?: number,
      userAdd?: string,
      startDate?: string,
      endDate?: string,
      payment?: string,
      phone?: string,
      requiresInvoice?: number,
      payment_status?: string,
      municipality?: string,
      complete_name?: string
    ) =>
      buildPaginatedOrdersUrl(
        size,
        page,
        statusNew,
        statusCanceled,
        statusInProgress,
        statusDone,
        number,
        userId,
        userAdd,
        startDate,
        endDate,
        payment,
        phone,
        requiresInvoice,
        payment_status,
        municipality,
        complete_name
      ),
    UPDATE_ORDER: (id: number) => `${ordersUrl}/${id}`,
    EXPORT: (export_type: string, filters: any) =>
      buildExportOrdersUrl(export_type, filters),
  },
  REPORTS: {
    EXPORT_SURVEYS: (export_type: string, filters: any) =>
      buildExportSurveysUrl(export_type, filters),
    PAGINATED_SURVEYS: (
      page: number,
      number?: string,
      user_name?: string,
      end_date?: string,
      start_date?: string
    ) =>
      buildPaginatedSurveysUrl(page, number, user_name, end_date, start_date),
    EXPORT_STAR_CLIENTS: (export_type: string, filters: any) =>
      buildExportStarClientsUrl(export_type, filters),
    PAGINATED_STAR_CLIENTS: (page: number, month?: string, year?: string) =>
      buildPaginatedStarClientUrl(page, month, year),
  },
};

const buildPaginatedPricesUrl = (
  page: number,
  startDate?: string,
  endDate?: string,
  product?: string
): string => {
  let baseUrl = `${pricingtUrl}/paginated/15/${page}`;
  const queryParams: string[] = [];
  if (startDate) queryParams.push(`start_date=${startDate}`);
  if (endDate) queryParams.push(`end_date=${endDate}`);
  if (product != 'all') queryParams.push(`product=${product}`);

  // Si hay parámetros de consulta, únelos con `&`
  if (queryParams.length > 0) {
    baseUrl += '?' + queryParams.join('&');
  }
  return baseUrl;
};

const buildPaginatedUsersUrl = (
  page: number,
  type_user: string,
  number?: string,
  name?: string,
  state?: string
): string => {
  let baseUrl = `${usersUrl}/paginated/15/${page}`;
  const queryParams: string[] = [];
  if (type_user) queryParams.push(`type_user=${type_user}`);
  if (number) queryParams.push(`number=${number}`);
  if (name) queryParams.push(`name=${name}`);
  if (state && state != 'all') queryParams.push(`state=${state}`);

  // Si hay parámetros de consulta, únelos con `&`
  if (queryParams.length > 0) {
    baseUrl += '?' + queryParams.join('&');
  }
  return baseUrl;
};

const buildPaginatedOrdersUrl = (
  size: number,
  page: number,
  statusNew: boolean,
  statusCanceled: boolean,
  statusInProgress: boolean,
  statusDone: boolean,
  number?: string,
  userId?: number,
  userAdd?: string,
  startDate?: string,
  endDate?: string,
  payment?: string,
  phone?: string,
  requiresInvoice?: number,
  paymentStatus?: string,
  municipality?: string,
  complete_name?: string
): string => {
  let baseUrl = `${ordersUrl}/paginated/${size}/${page}`;
  const queryParams: string[] = [];
  const statuses: string[] = [];
  if (statusNew) statuses.push('Nuevo');
  if (statusCanceled) statuses.push('Cancelado');
  if (statusInProgress) statuses.push('En proceso');
  if (statusDone) statuses.push('Terminado');
  if (statuses.length > 0) {
    queryParams.push(`estatus=${encodeURIComponent(statuses.join(','))}`);
  }
  if (number) queryParams.push(`number=${number}`);
  if (complete_name) queryParams.push(`client_name=${complete_name}`);
  if (userId) queryParams.push(`user_id=${userId}`);
  if (userAdd) queryParams.push(`employee_name=${userAdd}`);
  if (startDate) queryParams.push(`start_date=${startDate}`);
  if (endDate) queryParams.push(`end_date=${endDate}`);
  if (payment && payment != 'all') queryParams.push(`payment=${payment}`);
  if (phone) queryParams.push(`phone=${phone}`);
  if (requiresInvoice) queryParams.push(`requires_invoice=${requiresInvoice}`);
  if (paymentStatus && paymentStatus != 'all')
    queryParams.push(`estatus_payment=${paymentStatus}`);
  if (municipality) queryParams.push(`municipality=${municipality}`);

  // Si hay parámetros de consulta, únelos con `&`
  if (queryParams.length > 0) {
    baseUrl += '?' + queryParams.join('&');
  }
  return baseUrl;
};

const buildExportUsersUrl = (
  type_user: string,
  export_type: string,
  filters: any
): string => {
  let baseUrl = `${usersUrl}/list/${export_type}`;
  const queryParams: string[] = [];
  queryParams.push(`type_user=${type_user}`);
  if (filters.number) queryParams.push(`number=${filters.number}`);
  if (filters.name) queryParams.push(`name=${filters.name}`);
  if (filters.state && filters.state != 'all')
    queryParams.push(`state=${filters.state}`);

  // Si hay parámetros de consulta, únelos con `&`
  if (queryParams.length > 0) {
    baseUrl += '?' + queryParams.join('&');
  }
  return baseUrl;
};

const buildExportOrdersUrl = (export_type: string, filters: any): string => {
  let baseUrl = `${ordersUrl}/list/${export_type}`;
  const queryParams: string[] = [];
  if (filters.statusNew) queryParams.push(`estatus=Nuevo`);
  if (filters.statusCanceled) queryParams.push(`estatus=Cancelado`);
  if (filters.statusInProgress) queryParams.push(`estatus=En proceso`);
  if (filters.statusDone) queryParams.push(`estatus=Terminado`);
  if (filters.number) queryParams.push(`number=${filters.number}`);
  if (filters.complete_name)
    queryParams.push(`client_name=${filters.complete_name}`);
  if (filters.userAdd) queryParams.push(`employee_name=${filters.userAdd}`);
  if (filters.startDate) queryParams.push(`start_date=${filters.startDate}`);
  if (filters.endDate) queryParams.push(`end_date=${filters.endDate}`);
  if (filters.payment && filters.payment != 'all')
    queryParams.push(`payment=${filters.payment}`);
  if (filters.phone) queryParams.push(`client_phone=${filters.phone}`);
  if (filters.paymentStatus && filters.paymentStatus != 'all')
    queryParams.push(`estatus_payment=${filters.paymentStatus}`);
  if (filters.municipality)
    queryParams.push(`municipality=${filters.municipality}`);

  // Si hay parámetros de consulta, únelos con `&`
  if (queryParams.length > 0) {
    baseUrl += '?' + queryParams.join('&');
  }
  return baseUrl;
};

const buildPaginatedSurveysUrl = (
  page: number,
  number?: string,
  user_name?: string,
  end_date?: string,
  start_date?: string
): string => {
  let baseUrl = `${reportsUrl}/paginated/15/${page}`;
  const queryParams: string[] = [];
  if (number) queryParams.push(`number=${number}`);
  if (user_name) queryParams.push(`user_name=${user_name}`);
  if (start_date) queryParams.push(`start_date=${start_date}`);
  if (end_date) queryParams.push(`end_date=${end_date}`);

  // Si hay parámetros de consulta, únelos con `&`
  if (queryParams.length > 0) {
    baseUrl += '?' + queryParams.join('&');
  }
  return baseUrl;
};

const buildExportSurveysUrl = (export_type: string, filters: any): string => {
  let baseUrl = `${reportsUrl}/${export_type}`;
  const queryParams: string[] = [];
  if (filters.number) queryParams.push(`number=${filters.number}`);
  if (filters.user_name)
    queryParams.push(`cliente_nombre=${filters.user_name}`);
  if (filters.start_date)
    queryParams.push(`fecha_inicio=${filters.start_date}`);
  if (filters.end_date) queryParams.push(`fecha_fin=${filters.end_date}`);

  // Si hay parámetros de consulta, únelos con `&`
  if (queryParams.length > 0) {
    baseUrl += '?' + queryParams.join('&');
  }
  return baseUrl;
};

const buildPaginatedStarClientUrl = (
  page: number,
  month?: string,
  year?: string
): string => {
  let baseUrl = `${usersUrl}/starclients/list/15/${page}`;
  const queryParams: string[] = [];
  if (month) queryParams.push(`month=${month}`);
  if (year) queryParams.push(`year=${year}`);

  // Si hay parámetros de consulta, únelos con `&`
  if (queryParams.length > 0) {
    baseUrl += '?' + queryParams.join('&');
  }
  return baseUrl;
};

const buildExportStarClientsUrl = (
  export_type: string,
  filters: any
): string => {
  let baseUrl = `${usersUrl}/starclients/${export_type}/15`;
  const queryParams: string[] = [];
  if (filters.month) queryParams.push(`month=${filters.month}`);
  if (filters.year) queryParams.push(`year=${filters.year}`);

  // Si hay parámetros de consulta, únelos con `&`
  if (queryParams.length > 0) {
    baseUrl += '?' + queryParams.join('&');
  }
  return baseUrl;
};
