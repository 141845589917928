import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address } from '@interfaces';
import {
  lettersOnlyValidator,
  numebrsOnlyValidator,
  passwordContainsLetterAndNumber,
} from '@validators';
import { UserService } from 'app/features/users/services/user.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-client-address-modal',
  templateUrl: './edit-client-address-modal.component.html',
  styleUrl: './edit-client-address-modal.component.scss',
})
export class EditClientAddressModalComponent {
  @Input() addresses!: Address[];
  selectedAddress!: Address;
  @Input() modalRef!: BsModalRef;

  @Output() updated = new EventEmitter<Address>();

  submitted: boolean = false;
  addressForm: FormGroup;
  isVisible: boolean = false;
  activeAddress: number = 0;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.addressForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(20),
          Validators.minLength(2),
        ],
      ],
      recipient_phone: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
          numebrsOnlyValidator(),
        ],
      ],
      postal_code: [
        '',
        [
          Validators.required,
          Validators.maxLength(5),
          Validators.minLength(5),
          numebrsOnlyValidator(),
        ],
      ],
      street: [
        '',
        [
          Validators.required,
          Validators.maxLength(20),
          Validators.minLength(2),
        ],
      ],
      colony: [
        '',
        [
          Validators.required,
          Validators.maxLength(30),
          Validators.minLength(2),
        ],
      ],
      number: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(2),
        ],
      ],
      number_in: ['', [Validators.maxLength(10), Validators.minLength(2)]],
      recipient_name: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(2),
          lettersOnlyValidator(),
        ],
      ],
      location: [
        '',
        [
          Validators.required,
          Validators.maxLength(20),
          Validators.minLength(2),
          lettersOnlyValidator(),
        ],
      ],
      state: [[Validators.required]],
    });
  }

  ngOnInit() {
    this.changeAddress(0);
  }

  close = (): void => {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  };

  public onSubmit = async (): Promise<void> => {
    this.submitted = true;
    if (this.addressForm.valid) {
      this.addressForm.value.user_id = this.selectedAddress.user_id;
      await this.userService
        .updateAddress(this.addressForm.value, this.selectedAddress.id)
        .subscribe(({ data, code }) => {
          if (code === 500) {
            this.submitted = false;
            return this.toastr.error(
              'No fue posible actualizar la dirección',
              '¡Error!'
            );
          }
          this.updated.emit(data);
          this.toastr.success(`¡Dirección actualizada!`, '¡Éxito!');
          this.close();
          return;
        });
    }
  };

  get nameErrors() {
    const formValue = this.addressForm.get('name');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    } else if (formValue?.hasError('maxlength')) {
      return 'Debe contener máximo 20 caracteres.';
    } else if (formValue?.hasError('minlength')) {
      return 'Debe contener al menos 2 caracteres.';
    }
    return '';
  }

  get recipientPhoneErrors() {
    const formValue = this.addressForm.get('recipient_phone');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    } else if (formValue?.hasError('numbersOnly')) {
      return 'Solo se permiten números';
    } else if (formValue?.hasError('maxlength')) {
      return 'Debe contener máximo 20 caracteres.';
    } else if (formValue?.hasError('length')) {
      return 'Debe contener al menos 2 caractere.';
    }
    return '';
  }

  get postalCodeErrors() {
    const formValue = this.addressForm.get('postal_code');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    } else if (formValue?.hasError('numbersOnly')) {
      return 'Solo se permiten números';
    } else if (
      formValue?.hasError('maxlength') ||
      formValue?.hasError('minlength')
    ) {
      return 'Debe contener exactamente 5 digitos';
    }
    return '';
  }

  get streetErrors() {
    const formValue = this.addressForm.get('street');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    } else if (formValue?.hasError('maxlength')) {
      return 'Debe contener máximo 20 caracteres';
    } else if (formValue?.hasError('minlength')) {
      return 'Debe contener al menos 2 caracteres';
    }
    return '';
  }

  get colonyErrors() {
    const formValue = this.addressForm.get('colony');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    } else if (formValue?.hasError('maxlength')) {
      return 'Debe contener máximo 30 caracteres';
    } else if (formValue?.hasError('minlength')) {
      return 'Debe contener al menos 2 caracteres';
    }
    return '';
  }

  get numberErrors() {
    const formValue = this.addressForm.get('number');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    } else if (formValue?.hasError('maxlength')) {
      return 'Debe contener máximo 10 caracteres';
    } else if (formValue?.hasError('minlength')) {
      return 'Debe contener al menos 2 caracteres';
    }
    return '';
  }

  get numberInErrors() {
    const formValue = this.addressForm.get('number_in');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    } else if (formValue?.hasError('maxlength')) {
      return 'Debe contener máximo 10 caracteres';
    } else if (formValue?.hasError('minlength')) {
      return 'Debe contener al menos 2 caracteres';
    }
    return '';
  }

  get recipientNameErrors() {
    const formValue = this.addressForm.get('recipient_name');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    } else if (formValue?.hasError('maxlength')) {
      return 'Debe contener máximo 10 caracteres.';
    } else if (formValue?.hasError('minlength')) {
      return 'Debe contener al menos 2 caracteres.';
    }
    return '';
  }

  get stateErrors() {
    const emailControl = this.addressForm.get('state');
    if (emailControl?.hasError('required')) {
      return 'Campo requerido';
    }
    return '';
  }

  get municipalityErrors() {
    const nameControl = this.addressForm.get('location');
    if (nameControl?.hasError('required')) {
      return 'Campo requerido';
    } else if (nameControl?.hasError('lettersOnly')) {
      return 'Solo se permiten letras';
    } else if (nameControl?.hasError('maxlength')) {
      return 'Debe contener máximo 20 caracteres';
    } else if (nameControl?.hasError('minlength')) {
      return 'Debe contener al menos 2 caracteres';
    }
    return '';
  }

  onInput(event: Event, formField: string): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const trimmedValue = value.trimStart();
    this.addressForm
      .get(formField)
      ?.setValue(trimmedValue, { emitEvent: false });
  }

  onPaste(event: ClipboardEvent, formField: string): void {
    const pastedData = event.clipboardData?.getData('text') || '';
    const trimmedData = pastedData.trimStart();
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const cursorPosition = input.selectionStart ?? 0;
    const currentValue = input.value;
    const newValue =
      currentValue.slice(0, cursorPosition) +
      trimmedData +
      currentValue.slice(input.selectionEnd ?? cursorPosition);
    input.value = newValue.trimStart();
    this.addressForm.get(formField)?.setValue(input.value);
  }

  noChangesAddressInfo = (): boolean => {
    const {
      name: nameForm,
      recipient_phone: recipientPhoneForm,
      postal_code: postalCodeForm,
      street: streetForm,
      colony: colonyForm,
      number: numberForm,
      number_in: numberInForm,
      recipient_name: recipientNameForm,
      location: locationForm,
      state: stateForm,
    } = this.addressForm.value;

    const {
      name,
      recipient_phone,
      postal_code,
      street,
      colony,
      number,
      number_in,
      recipient_name,
      location,
      state,
    } = this.selectedAddress;
    return (
      name === nameForm &&
      recipient_phone === recipientPhoneForm &&
      postal_code === postalCodeForm &&
      street === streetForm &&
      colony === colonyForm &&
      number === numberForm &&
      number_in === numberInForm &&
      recipient_name === recipientNameForm &&
      location === locationForm &&
      state === stateForm
    );
  };

  isDisabled = (): boolean => {
    return (
      this.noChangesAddressInfo() || this.addressForm.invalid || this.submitted
    );
  };

  changeAddress = (index: number): void => {
    this.selectedAddress = this.addresses[index];
    this.activeAddress = index;
    this.addressForm.setValue({
      name: this.selectedAddress.name,
      recipient_phone: this.selectedAddress.recipient_phone,
      postal_code: this.selectedAddress.postal_code,
      street: this.selectedAddress.street,
      colony: this.selectedAddress.colony,
      number: this.selectedAddress.number,
      number_in: this.selectedAddress.number_in,
      recipient_name: this.selectedAddress.recipient_name,
      state: this.selectedAddress.state,
      location: this.selectedAddress.location
        ? this.selectedAddress.location
        : '',
    });
  };
}
