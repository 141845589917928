<div class="container-fluid ps-0 pe-3 mt-0 w-100 app-bg">
  <div class="card shadow border-0 card-bg">
    <div class="card-body">
      <div class="filter-container filter-container-50">
        <div class="input-group d-flex align-items-center">
          <label
            for="start-date"
            class="form-label ms-1 mb-0 fw-bolder text-color-2"
            >De:</label
          >
          <input
            id="start-date"
            type="date"
            class="form-control form-control-sm p-1"
            placeholder="dd/mm/aaaa"
            [(ngModel)]="filters.start_date"
            [attr.max]="currentDate"
          />
        </div>
        <div class="input-group d-flex align-items-center">
          <label
            for="end-date"
            class="form-label ms-1 mb-0 fw-bolder text-color-2"
            >A:</label
          >
          <input
            id="end-date"
            type="date"
            class="form-control form-control-sm p-1"
            placeholder="dd/mm/aaaa"
            [(ngModel)]="filters.end_date"
            [attr.max]="currentDate"
            [attr.min]="formatDate(filters.start_date)"
          />
        </div>
        <select
          class="form-select"
          [(ngModel)]="filters.product"
          placeholder="Tipo de cilindro"
        >
          <option [value]="cilynderType.all">Todos</option>
          <option [value]="cilynderType.cylinder20">20 kg</option>
          <option [value]="cilynderType.cylinder30">30 kg</option>
        </select>
        <div class="btn-group d-flex">
          <button
            type="button"
            class="btn btn-primary bg-ligth-mode-2 d-flex align-items-center justify-content-center"
            (click)="resetFilters()"
          >
            <img src="/assets/broom.svg" alt="arrow" />
          </button>
          <button
            type="button"
            class="btn btn-secondary bg-ligth-mode d-flex align-items-center justify-content-center"
            (click)="getPriceHistory()"
          >
            <img src="/assets/search.svg" alt="arrow" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid ps-0 pe-3 mt-0 pt-4 w-100">
  <div class="card shadow border-0 card-bg">
    <div class="card-body p-0">
      <ngx-datatable
        class="material card-bg"
        [columnMode]="'flex'"
        [headerHeight]="50"
        [footerHeight]="50"
        [columns]="columns"
        rowHeight="auto"
        [rows]="pricesList"
        [count]="itemCount"
        [externalPaging]="true"
        [offset]="filters.page"
        [limit]="15"
        (page)="setPage($event)"
        [loadingIndicator]="isLoading"
        [messages]="messages"
      />
      <app-export-buttons [module]="exportModule" />
    </div>
  </div>
</div>
