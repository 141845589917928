import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Password, User } from '@interfaces';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from './services/profile.service';
import { AuthService } from 'app/core/auth/auth.service';
import { Router } from '@angular/router';
import {
  lettersOnlyValidator,
  matchPasswordValidator,
  noSpacesEmailValidator,
  compositePasswordValidator,
} from '@validators';
import { UserUpdateDto } from 'app/core/dtos/user-update.dto';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent {
  submitted: boolean = false;
  profileForm: FormGroup;
  user!: User;
  userData: any;
  imageSrc: string | ArrayBuffer | null = null;
  isVisible: boolean = false;
  isVisibleConfirm: boolean = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private profileService: ProfileService,
    private authService: AuthService
  ) {
    this.profileForm = this.fb.group(
      {
        number: [{ value: '', disabled: true }, [Validators.required]],
        complete_name: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            Validators.minLength(1),
            lettersOnlyValidator(),
          ],
        ],
        email: ['', [Validators.required, noSpacesEmailValidator()]],
        password: ['', [compositePasswordValidator()]],
        confirmPassword: ['', []],
      },
      {
        validators: matchPasswordValidator('password', 'confirmPassword'),
      }
    );
  }

  ngOnInit() {
    this.authService.user$.subscribe((user) => {
      this.userData = user;
      this.imageSrc = this.userData.path_profile;
    });
    this.profileForm.setValue({
      email: this.userData.email,
      complete_name: this.userData.complete_name,
      number: this.userData.number,
      password: '',
      confirmPassword: '',
    });
  }

  public onSubmit = async (): Promise<void> => {
    this.submitted = true;
    if (!this.profileForm.valid) {
      return;
    }
    const { email, complete_name } = this.profileForm.value;
    const userData: UserUpdateDto = {
      email,
      complete_name,
      number: this.userData.number,
      id: this.userData.id,
      phone: this.userData.phone,
      type_user: this.userData.type_user,
      last_name: 'na',
      name: 'na',
    };
    if (this.canSaveUser(userData)) {
      await this.saveUser(userData, this.userData.id);
    }
    const newPassword = this.profileForm.get('password')?.value;
    const confirmPassword = this.profileForm.get('confirmPassword')?.value;
    if (newPassword != '' && confirmPassword != '') {
      const passwordData: Password = {
        new_password: newPassword,
        id: this.userData.id,
      };
      this.savePassword(passwordData);
    }
  };

  public saveUser = async (
    userData: UserUpdateDto,
    userId: number
  ): Promise<void> => {
    await this.profileService
      .updateUser(userData, userId)
      .subscribe(({ data, code }) => {
        if (code === 500) {
          this.submitted = false;
          return this.toastr.error(
            'No se ha podido actualizar la información del perfil',
            '¡Error!'
          );
        }
        this.submitted = false;
        this.authService.setSession(data);
        this.toastr.success(`¡Información de perfil actualizada!`, '¡Éxito!');
        return;
      });
  };

  public savePassword = async (passwordData: Password): Promise<void> => {
    await this.profileService
      .updatePassword(passwordData)
      .subscribe(({ code, data }) => {
        if (code === 500) {
          this.submitted = false;
          return this.toastr.error(
            'No se ha podido actualizar la contraseña',
            '¡Error!'
          );
        }
        this.submitted = false;
        this.authService.setSession(data);
        this.profileForm.patchValue({
          password: '',
          confirmPassword: '',
        });
        this.toastr.success(`¡Contraseña actualizada!`, '¡Éxito!');
        return;
      });
  };

  public saveProfilePicture = async (
    image: File,
    userId: number
  ): Promise<void> => {
    await this.profileService
      .updateProfilePicture(image, userId)
      .subscribe(({ code, data }) => {
        if (code === 500) {
          this.imageSrc = null;
          this.toastr.error(
            'No fue posible actualizar la imagen de perfil',
            '¡Error!'
          );
          return;
        }
        this.authService.setSession(data);
        this.toastr.success('Imagen de perfil actualizada', '¡Éxito!');
        return;
      });
  };

  get emailErrors() {
    const emailControl = this.profileForm.get('email');
    if (emailControl?.hasError('required')) {
      return 'Campo requerido';
    } else if (emailControl?.hasError('noSpacesEmail')) {
      return 'Formato inválido';
    }
    return '';
  }

  get completeNameErrors() {
    const nameControl = this.profileForm.get('complete_name');
    if (nameControl?.hasError('required')) {
      return 'Campo requerido';
    } else if (nameControl?.hasError('lettersOnly')) {
      return 'Solo se permiten letras';
    } else if (nameControl?.hasError('maxlength')) {
      return 'Debe tener máximo 20 caracteres';
    } else if (nameControl?.hasError('minlength')) {
      return 'Debe tener mínimo 1 caracter';
    }
    return '';
  }

  get passwordErrors() {
    const passwordControl = this.profileForm.get('password');
    if (passwordControl?.hasError('required')) {
      return 'Contraseña requerida.';
    } else if (passwordControl?.hasError('minlength')) {
      return 'Debe tener mínimo 8 caracteres.';
    } else if (passwordControl?.hasError('maxlength')) {
      return 'Debe tener máximo 20 caracteres.';
    } else if (passwordControl?.hasError('noSpaces')) {
      return 'No puede contener espacios.';
    } else if (passwordControl?.hasError('containsLetterAndNumber')) {
      return 'Debe contener letras y números.';
    }
    return '';
  }

  get confirmPasswordErrors() {
    const confirmPasswordControl = this.profileForm.get('confirmPassword');
    if (confirmPasswordControl?.hasError('required')) {
      return 'Confirmar contraseña requerida.';
    } else if (confirmPasswordControl?.hasError('matchPassword')) {
      return 'Las contraseñas no coinciden.';
    }
    return '';
  }

  canSaveUser({ complete_name, email }: UserUpdateDto) {
    return (
      complete_name !== this.userData.complete_name ||
      email !== this.userData.email
    );
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (file) {
      if (this.isValidImage(file)) {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          this.imageSrc = e.target?.result ?? null;
          this.saveProfilePicture(file, this.userData.id);
        };
        reader.readAsDataURL(file);
      } else {
        this.toastr.error('La imagen debe ser formato .png o .jpg', '¡Error!');
        this.imageSrc = null;
      }
    }
  }

  private isValidImage(file: File): boolean {
    const allowedTypes = ['image/png', 'image/jpeg'];
    return allowedTypes.includes(file.type);
  }

  triggerFileInput(): void {
    const input = document.getElementById('file-input') as HTMLInputElement;
    input.click();
  }

  changePasswordVisibility = (useIsVisible?: boolean): void => {
    if (useIsVisible) {
      this.isVisible = !this.isVisible;
      return;
    }
    this.isVisibleConfirm = !this.isVisibleConfirm;
  };

  noChangesUserInfo = (): boolean => {
    const {
      complete_name: complete_name_form,
      password,
      confirmPassword,
      email: emailForm,
    } = this.profileForm.value;
    const { complete_name, email } = this.userData;
    return (
      complete_name === complete_name_form &&
      email === emailForm &&
      !password &&
      !confirmPassword
    );
  };

  isDisabled = (): boolean => {
    return (
      this.noChangesUserInfo() || this.profileForm.invalid || this.submitted
    );
  };

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const trimmedValue = value.trimStart();
    this.profileForm
      .get('complete_name')
      ?.setValue(trimmedValue, { emitEvent: false });
  }

  onPaste(event: ClipboardEvent): void {
    const pastedData = event.clipboardData?.getData('text') || '';
    const trimmedData = pastedData.trimStart();
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const cursorPosition = input.selectionStart ?? 0;
    const currentValue = input.value;
    const newValue =
      currentValue.slice(0, cursorPosition) +
      trimmedData +
      currentValue.slice(input.selectionEnd ?? cursorPosition);
    input.value = newValue.trimStart();
    this.profileForm.patchValue({ complete_name: input.value });
  }

  removeInitialSpaces() {
    const control = this.profileForm.get('password');
    const value = control?.value || '';
    control?.setValue(value.trimStart());
  }
}
