import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/core/auth/auth.service';
import { OrderListItem } from 'app/core/interfaces/order-list-item.interface';
import { OrderService } from 'app/features/orders/services/order.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-set-comments-modal',
  templateUrl: './set-comments-modal.component.html',
  styleUrl: './set-comments-modal.component.scss',
})
export class SetCommentsModalComponent {
  @Input() order!: OrderListItem;
  @Input() modalRef!: BsModalRef;

  @Output() updated = new EventEmitter<OrderListItem>();

  commentForm: FormGroup;
  submitted: boolean = false;

  constructor(
    private orderService: OrderService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.commentForm = this.fb.group({
      response: ['', [Validators.maxLength(500)]],
    });
  }

  ngOnInit() {
    this.commentForm.setValue({
      reponse: this.order.response,
    });
  }

  get commentErrors() {
    const formValue = this.commentForm.get('response');
    if (formValue?.hasError('maxlength')) {
      return 'Debe contener máximo 500 caracteres';
    }
    return '';
  }

  close = (): void => {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  };

  public onSubmit = async (): Promise<void> => {
    this.submitted = true;
    if (this.commentForm.valid) {
      this.order.response = this.commentForm.value.response;
      if (!this.order.response) {
        this.close();
      }
      await this.orderService
        .updateOrder(this.order)
        .subscribe(({ data, code }) => {
          if (code === 500) {
            this.submitted = false;
            return this.toastr.error(
              'No fue posible agregar un comentario a orden',
              '¡Error!'
            );
          }
          this.updated.emit(data);
          this.toastr.success(`¡Comentario generado!`, '¡Éxito!');
          this.close();
          return;
        });
    }
  };
}
