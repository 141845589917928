<div class="container-fluid ps-0 pe-3 mt-0 w-100 app-bg">
  <div class="card shadow border-0 card-bg">
    <div class="card-body">
      <div class="filter-container filter-container-25">
        <div class="input-group d-flex align-items-center">
          <input
            type="text"
            class="form-control month-picker"
            placeholder="Mes"
            bsDatepicker
            [bsConfig]="config"
            [(ngModel)]="month"
          />
        </div>
        <div class="input-group d-flex align-items-center">
          <input
            type="text"
            class="form-control"
            placeholder="Año"
            [(ngModel)]="year"
            bsDatepicker
            [bsConfig]="{
              dateInputFormat: 'YYYY',
              minMode: 'year',
            }"
          />
        </div>
        <div class="btn-group d-flex">
          <button
            type="button"
            class="btn btn-primary bg-ligth-mode-2 d-flex align-items-center justify-content-center"
            (click)="resetFilters()"
          >
            <img src="/assets/broom.svg" alt="arrow" />
          </button>
          <button
            type="button"
            class="btn btn-secondary bg-ligth-mode d-flex align-items-center justify-content-center"
            (click)="getStarClients()"
          >
            <img src="/assets/search.svg" alt="arrow" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid ps-0 pe-3 mt-0 pt-4 mb-3 w-100">
  <div class="card shadow border-0 card-bg">
    <div class="card-body p-0">
      <ngx-datatable
        #startClientTable
        class="material card-bg"
        [headerHeight]="50"
        [footerHeight]="50"
        [columns]="columns"
        [columnMode]="'force'"
        rowHeight="auto"
        [rows]="starClientList"
        [count]="itemCount"
        [externalPaging]="true"
        [offset]="filters.page"
        [limit]="15"
        (page)="setPage($event)"
        [loadingIndicator]="isLoading"
        [messages]="messages"
        (sort)="onSort($event)"
      >
        <ng-template #index let-row="row" let-rowIndex="rowIndex">
          <div class="d-flex justify-content-center">
            <label class="fw-bolder">
              {{ rowIndex + 1 }}
            </label>
          </div>
        </ng-template>
      </ngx-datatable>
      <div class="d-flex flex-colum align-items-center justify-content-end p-3">
        <button
          class="btn btn-solid-2-bg m-2 w-25"
          type="button"
          (click)="export(exportType.EXCEL)"
        >
          Exportar EXCEL
        </button>
        <button
          class="btn btn-solid-2-bg m-2 w-25"
          type="button"
          (click)="export(exportType.PDF)"
        >
          Exportar PDF
        </button>
      </div>
    </div>
  </div>
</div>
