<div class="container-fluid h-100">
  <div class="row justify-content-center align-items-center app-bg h-100">
    <a
      [routerLink]="['/login']"
      class="ms-3 mt-3 link-underline link-underline-opacity-0 text-color"
      ><img
        class="me-3"
        src="/assets/arrow-light.svg"
        [src]="
          isDarkMode
            ? '/assets/arrow-light-dark.svg'
            : '/assets/arrow-light.svg'
        "
        alt="arrow"
      />Volver atrás</a
    >
    <div
      class="col-md-4 mt-5 d-flex flex-column justify-content-center align-items-center pb-5 mb-5"
    >
      <img
        [src]="
          isDarkMode
            ? '/assets/gas-lago-recover-password-dark.svg'
            : '/assets/gas-lago-recover-password.svg'
        "
        alt="gas-lago"
        class="img-medium mb-5"
      />
      <form
        [formGroup]="recoverForm"
        (ngSubmit)="onSubmit()"
        class="text-center w-100"
      >
        <div class="form-group mb-4">
          <div class="input-group">
            <input
              type="text"
              class="form-control border-end-0"
              placeholder="Número de empleado"
              formControlName="number"
            />
            <span class="input-group-text bg-transparent">
              <img src="/assets/user.svg" alt="user" />
            </span>
          </div>
          <div
            *ngIf="
              recoverForm.get('number')?.dirty ||
              recoverForm.get('number')?.touched
            "
          >
            <div class="text-danger">
              {{ numberErrors }}
            </div>
          </div>
        </div>
        <p class="text-color-2 mb-4">
          Recuerda cambiar tu contraseña una vez que inicies sesión
        </p>
        <button
          class="btn btn-solid-bg w-100 mt-3"
          type="submit"
          [disabled]="!recoverForm.valid || submitted"
        >
          Enviar
          <img
            class="ms-4"
            src="/assets/arrow.svg"
            [src]="isDarkMode ? '/assets/arrow-dark.svg' : '/assets/arrow.svg'"
            alt="arrow"
          />
        </button>
      </form>
    </div>
  </div>
</div>
