<app-navbar [title]="'Pedidos'"></app-navbar>
<div class="container-fluid ps-0 pe-3 mt-3 w-100 app-bg">
  <div class="card shadow border-0 card-bg">
    <div class="card-body">
      <div class="d-flex justify-content-start mb-3">
        <div class="filter-container filter-container-35">
          <div class="input-group d-flex align-items-center ms-1">
            <label for="start-date" class="text-color-2">De: </label>
            <input
              id="start-date"
              type="date"
              class="form-control form-control p-1"
              placeholder="dd/mmm/aa"
              [(ngModel)]="temporaryFilters.startDate"
            />
          </div>
          <div class="input-group d-flex align-items-center">
            <label for="end-date" class="text-color-2">A: </label>
            <input
              id="end-date"
              type="date"
              class="form-control form-control p-1"
              placeholder="dd/mmm/aa"
              [(ngModel)]="temporaryFilters.endDate"
            />
          </div>
          <div class="btn-group d-flex">
            <button
              type="button"
              class="btn btn-primary bg-ligth-mode-2 d-flex align-items-center justify-content-center"
              (click)="resetDates()"
            >
              <img src="/assets/broom.svg" alt="broom" />
            </button>
            <button
              type="button"
              class="btn btn-secondary bg-ligth-mode d-flex align-items-center justify-content-center"
            >
              <img src="/assets/chevron-white.svg" alt="chevron" />
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="filter-container">
          <div class="input-group d-flex align-items-center">
            <input
              id="complete-name"
              type="text"
              class="form-control form-control p-1"
              placeholder="Nombre del cliente"
              [(ngModel)]="temporaryFilters.complete_name"
            />
          </div>
          <div class="input-group d-flex align-items-center">
            <input
              id="number"
              type="text"
              class="form-control form-control p-1"
              placeholder="# de folio"
              [(ngModel)]="temporaryFilters.number"
            />
          </div>
          <div class="input-group d-flex align-items-center">
            <input
              id="phone"
              type="text"
              class="form-control form-control p-1"
              placeholder="Telefóno del cliente"
              [(ngModel)]="temporaryFilters.phone"
            />
          </div>
          <select class="form-select" [(ngModel)]="temporaryFilters.payment">
            <option value="all">Tipo de pago</option>
            <option value="En línea">En línea</option>
            <option value="Tarjeta Débito/Crédito">
              Tarjeta Débito/Crédito
            </option>
            <option value="Efectivo">Efectivo</option>
          </select>
          <select
            class="form-select"
            [(ngModel)]="temporaryFilters.paymentStatus"
          >
            <option value="all">Estatus de pago</option>
            <option value="Pagado">Pagado</option>
            <option value="Sin asignar">Sin asignar</option>
            <option value="Asignado">Asignado</option>
          </select>
          <div class="input-group d-flex align-items-center">
            <input
              id="municipality"
              type="text"
              class="form-control form-control p-1"
              placeholder="Municipio"
              [(ngModel)]="temporaryFilters.municipality"
            />
          </div>
          <div class="input-group d-flex align-items-center">
            <input
              id="userAdd"
              type="text"
              class="form-control form-control p-1"
              placeholder="Nombre de empleado"
              [(ngModel)]="temporaryFilters.userAdd"
            />
          </div>
          <select
            class="form-select"
            [(ngModel)]="estatus"
            (change)="setEstatus()"
          >
            <option value="all">Estatus de pedido</option>
            <option value="Nuevo">Nuevo</option>
            <option value="En proceso">En proceso</option>
            <option value="Terminado">Terminado</option>
            <option value="Cancelado">Cancelado</option>
          </select>
          <div class="btn-group d-flex">
            <button
              type="button"
              class="btn btn-primary bg-ligth-mode-2 d-flex align-items-center justify-content-center"
              (click)="resetFilters()"
            >
              <img src="/assets/broom.svg" alt="arrow" />
            </button>
            <button
              type="button"
              class="btn btn-secondary bg-ligth-mode d-flex align-items-center justify-content-center"
              (click)="filterOrders()"
            >
              <img src="/assets/search.svg" alt="arrow" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid ps-0 pe-3 mt-0 pt-4 mb-3 w-100">
  <div class="card shadow border-0 card-bg">
    <div class="card-body p-0">
      <ngx-datatable
        #orderTable
        class="material card-bg"
        [headerHeight]="80"
        [footerHeight]="50"
        [columns]="columns"
        [columnMode]="'standard'"
        rowHeight="auto"
        [rows]="orderList"
        [count]="itemCount"
        [externalPaging]="true"
        [offset]="filters.page"
        [limit]="15"
        (page)="setPage($event)"
        [loadingIndicator]="isLoading"
        [scrollbarH]="true"
        [messages]="messages"
        (sort)="onSort($event)"
      >
        <ng-template #folioButton let-row="row">
          <div class="d-flex justify-content-center align-items-center">
            <p class="truncate-text">
              {{ row.number != "" ? row.number : "Sin folio" }}
            </p>

            <button
              *appHasRole="['admin_role', 'call_center_role']"
              class="btn btn-primary d-flex justify-content-center align-items-center orders-small-btn ms-1"
              (click)="openSetFolioModal(row)"
            >
              <img src="assets/edit-2.svg" />
            </button>
          </div>
        </ng-template>
        <ng-template #address let-row="row">
          <div class="d-flex justify-content-center align-items-center">
            {{ getAddress(row.address) }}
          </div>
        </ng-template>
        <ng-template #day let-row="row">
          <div class="d-flex justify-content-center align-items-center">
            {{ getDay(row.created_at) }}
          </div>
        </ng-template>
        <ng-template #hour let-row="row">
          <div class="d-flex justify-content-center align-items-center">
            {{ getHour(row.created_at) }}
          </div>
        </ng-template>
        <ng-template #employee let-row="row">
          <div class="d-flex justify-content-center align-items-center">
            {{ row.employee ? row.employee.complete_name : "Sin asignar" }}
          </div>
        </ng-template>
        <ng-template #orderStatusButton let-row="row">
          <div class="d-flex align-items-center justify-content-center w-100">
            <span
              class="badge rounded-pill text-bg-primary pill-font-size d-flex m-1"
              [ngClass]="setStatusColor(row.estatus)"
              >{{ row.estatus }}</span
            >
            <button
              *appHasRole="['admin_role', 'call_center_role']"
              class="btn btn-primary d-flex justify-content-center align-items-center orders-small-btn ms-1"
              (click)="openSetOrderStatusModal(row)"
            >
              <img src="assets/edit-2.svg" alt="" />
            </button>
          </div>
        </ng-template>
        <ng-template #paymentStatusButton let-row="row">
          <div class="d-flex align-items-center justify-content-center w-100">
            <span
              class="badge rounded-pill text-bg-primary pill-font-size d-flex m-1"
              [ngClass]="setStatusColor(row.estatus_payment)"
              >{{ row.estatus_payment }}</span
            >
            <button
              *appHasRole="['admin_role', 'call_center_role']"
              class="btn btn-primary d-flex justify-content-center align-items-center orders-small-btn ms-1"
              (click)="openSetPaymentStatusModal(row)"
            >
              <img src="assets/edit-2.svg" alt="" />
            </button>
          </div>
        </ng-template>
        <ng-template #orderDetailsButton let-row="row">
          <div class="d-flex align-items-center justify-content-center w-100">
            <button
              class="btn btn-dark bg-dark-ligh-mode btn-size-40 d-flex justify-content-center align-items-center"
              (click)="openDetailsModal(row)"
            >
              <img src="assets/eye.svg" />
            </button>
          </div>
        </ng-template>
        <ng-template #billing let-row="row">
          <div class="d-flex align-items-center justify-content-center w-100">
            <p [ngClass]="row.requires_invoice === 1 ? 'text-danger' : ''">
              {{ row.requires_invoice === 1 ? "SI" : "NO" }}
            </p>
          </div>
        </ng-template>
        <ng-template #billSended let-row="row">
          <div class="d-flex align-items-center justify-content-center w-100">
            <p [ngClass]="row.invoice_generated === 1 ? 'text-danger' : ''">
              {{ row.invoice_generated === 1 ? "SI" : "NO" }}
            </p>
          </div>
        </ng-template>
      </ngx-datatable>
      <div class="d-flex flex-colum align-items-center justify-content-end p-3">
        <button
          class="btn btn-solid-2-bg m-2 w-25"
          type="button"
          (click)="export(exportType.EXCEL)"
        >
          Exportar EXCEL
        </button>
        <button
          class="btn btn-solid-2-bg m-2 w-25"
          type="button"
          (click)="export(exportType.PDF)"
        >
          Exportar PDF
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #edit> </ng-template>

<ng-template #address> </ng-template>

<ng-template #billing> </ng-template>

<ng-template #setFolio>
  <app-set-folio-modal
    [modalRef]="modalRef"
    [order]="selectedOrder"
    (updated)="openSetAmountModal()"
  />
</ng-template>

<ng-template #amount>
  <app-change-amount-modal
    [modalRef]="modalRef"
    [order]="selectedOrder"
    (updated)="openSetCommentsModal()"
  />
</ng-template>

<ng-template #setOrderStatus>
  <app-set-order-status-modal
    [modalRef]="modalRef"
    [order]="selectedOrder"
    (updated)="getOrders()"
    (orderCanceled)="openSetCommentsModal()"
  />
</ng-template>

<ng-template #setPaymentStatus>
  <app-set-payment-status-modal
    [modalRef]="modalRef"
    [order]="selectedOrder"
    (updated)="getOrders()"
  />
</ng-template>

<ng-template #setComments>
  <app-set-comments-modal
    [modalRef]="modalRef"
    [order]="selectedOrder"
    (updated)="getOrders()"
  />
</ng-template>

<ng-template #details>
  <app-order-details-modal
    (closed)="getOrders()"
    [modalRef]="modalRef"
    [order]="selectedOrder"
  />
</ng-template>

<ng-template #bill>
  <app-upload-bill-modal
    [modalRef]="modalRef"
    [order]="selectedOrder"
    (updated)="getOrders()"
  />
</ng-template>
