<app-navbar [title]="'Inicio'"></app-navbar>
<div class="container-fluid ps-0 pe-3 mt-0 pt-4 w-100 app-bg text-color-2">
  <h5 class="h5 fw-bolder">{{ getSaludo() }}, {{ getName() }}</h5>
  <p class="f">Último inicio de sesión: {{ user.sessionStarted }}</p>

  <div class="d-flex">
    <div
      *ngFor="let status of orderStatus"
      class="card card-border w-25 mx-2 border-0 shadow card-bg"
    >
      <div class="card-body d-flex align-items-center py-2">
        <span class="circle border-0" [ngClass]="status.class">
          <img [src]="status.src" alt="info" />
        </span>
        <div class="d-flex flex-column ms-2 text-color-2">
          <h3 class="fw-bolder">{{ status.total }}</h3>
          <p class="mb-0">{{ status.label }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid ps-0 pe-3 mt-3 mb-3 w-100 app-bg">
  <div class="card shadow border-0 card-bg">
    <div class="card-body">
      <div
        class="header d-flex justify-content-between align-items-center text-color-2"
      >
        <h6 class="mb-0">Pedidos recientes</h6>
        <div class="d-flex align-items-center">
          <p class="mb-0">Ver pedidos:</p>
          <span
            class="badge rounded-pill d-flex m-1 clickable dashboard-filter-bg"
            *ngFor="let status of orderStatus"
            [ngClass]="setClass(status.estatus).cssClass"
            (click)="setActive(status.estatus)"
            >{{ status.estatus }}</span
          >
          <button
            class="btn btn-solid-2-bg btn-sm btn-rounded-custom py-0 fw-bold d-flex align-items-center"
            (click)="resetFilters()"
          >
            <img src="/assets/broom.svg" class="me-1" />Limpiar filtros
          </button>
        </div>
      </div>
      <hr class="custom-hr" />
      <div class="table-container w-100 px-4">
        <div *ngFor="let order of orderList" class="mb-2">
          <div class="card shadow border-0 dashboard-card-bg">
            <div class="card-body p-1">
              <div class="row">
                <div class="col-1">
                  <div
                    class="d-flex align-items-center justify-content-center h-100"
                  >
                    <img [src]="setTruck(order.estatus).src" />
                  </div>
                </div>
                <div class="col-2">
                  <div class="d-flex flex-column">
                    <label>Pedido</label>
                    <h6 class="fw-bold">
                      {{ order.number ? order.number : "Sin Folio" }}
                    </h6>
                  </div>
                </div>
                <div class="col-3">
                  <div class="d-flex flex-column">
                    <label>Forma de pago</label>
                    <h6 class="fw-bold">{{ order.payment }}</h6>
                  </div>
                </div>
                <div class="col-2">
                  <div class="d-flex flex-column">
                    <label>Tipo de pedido</label>
                    <h6 class="fw-bold">{{ order.type_product }}</h6>
                  </div>
                </div>
                <div class="col-2">
                  <div class="d-flex flex-column">
                    <label>Fecha de pedido</label>
                    <h6 class="fw-bold dasboard-order-date-color">
                      {{ order.created_at }}
                    </h6>
                  </div>
                </div>
                <div class="col-2">
                  <div
                    class="d-flex align-items-center justify-content-center h-100"
                  >
                    <span
                      class="badge rounded-pill d-flex m-1"
                      [ngClass]="setTruck(order.estatus).cssClass"
                      >{{ order.estatus }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
