<div class="container-fluid ps-0 pe-3 mt-0 w-100 app-bg">
  <div class="card shadow border-0 card-bg">
    <div class="card-body">
      <div class="filter-container filter-container-50">
        <div class="input-group d-flex align-items-center">
          <input
            id="folio"
            type="text"
            class="form-control form-control p-1"
            placeholder="# de folio"
            [(ngModel)]="filters.number"
          />
        </div>
        <div class="input-group d-flex align-items-center w-100">
          <input
            id="name"
            type="text"
            class="form-control p-1"
            placeholder="Nombre"
            [(ngModel)]="filters.user_name"
          />
        </div>
        <div class="input-group d-flex align-items-center ms-1">
          <label for="start-date" class="text-color-2">De: </label>
          <input
            id="start-date"
            type="date"
            class="form-control p-1"
            placeholder="dd/mmm/aa"
            [(ngModel)]="filters.start_date"
          />
        </div>
        <div class="input-group d-flex align-items-center">
          <label for="end-date" class="text-color-2">A: </label>
          <input
            id="end-date"
            type="date"
            class="form-control p-1"
            placeholder="dd/mmm/aa"
            [(ngModel)]="filters.end_date"
          />
        </div>
        <div class="btn-group d-flex">
          <button
            type="button"
            class="btn btn-primary bg-ligth-mode-2 d-flex align-items-center justify-content-center"
            (click)="resetFilters()"
          >
            <img src="/assets/broom.svg" alt="arrow" />
          </button>
          <button
            type="button"
            class="btn btn-secondary bg-ligth-mode d-flex align-items-center justify-content-center"
            (click)="getSurveys()"
          >
            <img src="/assets/search.svg" alt="arrow" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid ps-0 pe-3 mt-0 pt-4 mb-3 w-100">
  <div class="card shadow border-0 card-bg">
    <div class="card-body p-0">
      <ngx-datatable
        #surveyTable
        class="material card-bg"
        [headerHeight]="95"
        [footerHeight]="50"
        [columns]="columns"
        [columnMode]="'standard'"
        rowHeight="auto"
        [rows]="surveyList"
        [count]="itemCount"
        [externalPaging]="true"
        [offset]="filters.page"
        [limit]="15"
        (page)="setPage($event)"
        [loadingIndicator]="isLoading"
        [scrollbarH]="true"
        [messages]="messages"
        (sort)="onSort($event)"
      >
        <ng-template #question1 let-row="row">
          <div class="d-flex align-items-center justify-content-center w-100">
            <p>
              {{ getQuestionMessage(row, 1) }}
            </p>
          </div>
        </ng-template>
        <ng-template #question2 let-row="row">
          <div class="d-flex align-items-center justify-content-center w-100">
            <p>
              {{ getQuestionMessage(row, 2) }}
            </p>
          </div>
        </ng-template>
        <ng-template #question3 let-row="row">
          <div class="d-flex align-items-center justify-content-center w-100">
            <p>
              {{ getQuestionMessage(row, 3) }}
            </p>
          </div>
        </ng-template>
        <ng-template #question4 let-row="row">
          <div class="d-flex align-items-center justify-content-center w-100">
            <p>
              {{ getQuestionMessage(row, 4) }}
            </p>
          </div>
        </ng-template>
        <ng-template #question5 let-row="row">
          <div class="d-flex align-items-center justify-content-center w-100">
            <p>
              {{ getQuestionMessage(row, 5) }}
            </p>
          </div>
        </ng-template>
        <ng-template #surveyDetails let-row="row">
          <div class="d-flex align-items-center justify-content-center w-100">
            <button
              *ngIf="row.poll_answers && row.poll_answers.length > 0"
              class="btn btn-dark bg-dark-ligh-mode btn-size-40 d-flex justify-content-center align-items-center"
              (click)="openDetailsModal(row)"
            >
              <img src="assets/eye.svg" />
            </button>
          </div>
        </ng-template>
      </ngx-datatable>
      <div class="d-flex flex-colum align-items-center justify-content-end p-3">
        <button
          class="btn btn-solid-2-bg m-2 w-25 custom-hover"
          type="button"
          (click)="export(exportType.EXCEL)"
        >
          Exportar EXCEL
        </button>
        <button
          class="btn btn-solid-2-bg m-2 w-25 custom-hover"
          type="button"
          (click)="export(exportType.PDF)"
        >
          Exportar PDF
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #surveyDetailsModal>
  <app-survey-details-modal
    [modalRef]="modalRef"
    [survey]="selectedSurvey"
    (updated)="getSurveys()"
  />
</ng-template>
