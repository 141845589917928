import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderStatus, ProductType } from '@enum';
import { environment } from '@env/environment';
import { OrderListItem } from 'app/core/interfaces/order-list-item.interface';
import { FileService } from 'app/core/services/file.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-order-details-modal',
  templateUrl: './order-details-modal.component.html',
  styleUrl: './order-details-modal.component.scss',
})
export class OrderDetailsModalComponent {
  @Input() order!: OrderListItem;
  @Input() modalRef!: BsModalRef;

  @Output() updated = new EventEmitter<OrderListItem>();

  productType = ProductType;

  submitted: boolean = false;

  constructor(private fileService: FileService) {}

  getAddress(): string {
    const { address } = this.order;
    return ` ${address.street}, ${address.number}${
      address.number_in ? ' ' + address.number_in : ''
    }, ${address.colony}, ${address.location}, ${address.state}`;
  }

  setStatusColor(estatus: string) {
    let cssClass: string = '';
    switch (estatus) {
      case OrderStatus.COMPLETED:
        cssClass = 'status-finished-bg';
        break;
      case OrderStatus.NEW:
        cssClass = 'status-new-bg';
        break;
      case OrderStatus.CANCELED:
        cssClass = 'status-canceled-bg';
        break;
      case OrderStatus.IN_PROGRESS:
        cssClass = 'status-inprocess-bg';
        break;
    }
    return cssClass;
  }

  dowloadFile() {
    const url = `${environment.storageRecordsUrl}${this.order.fiscal_data.record}`;
    this.fileService.downloadFile(url, this.order.fiscal_data.record);
  }
}
