import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentStatus } from '@enum';
import { OrderListItem } from 'app/core/interfaces/order-list-item.interface';
import { OrderService } from 'app/features/orders/services/order.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-set-payment-status-modal',
  templateUrl: './set-payment-status-modal.component.html',
  styleUrl: './set-payment-status-modal.component.scss',
})
export class SetPaymentStatusModalComponent {
  @Input() order!: OrderListItem;
  @Input() modalRef!: BsModalRef;

  @Output() updated = new EventEmitter<OrderListItem>();

  paymentStatusForm: FormGroup;
  submitted: boolean = false;
  paymentStatus = PaymentStatus;

  constructor(
    private orderService: OrderService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.paymentStatusForm = this.fb.group({
      estatus_payment: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.paymentStatusForm.setValue({
      estatus_payment: this.order.estatus_payment,
    });
  }

  get paymentStatusErrors() {
    const formValue = this.paymentStatusForm.get('estatus_payment');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    }
    return '';
  }

  close = (): void => {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  };

  public onSubmit = async (): Promise<void> => {
    this.submitted = true;
    if (this.paymentStatusForm.valid) {
      this.order.estatus_payment = this.paymentStatusForm.value.estatus_payment;
      await this.orderService
        .updateOrder(this.order)
        .subscribe(({ data, code }) => {
          if (code === 500) {
            this.submitted = false;
            return this.toastr.error(
              'No fue posible actualizar el estatus del pago',
              '¡Error!'
            );
          }
          this.updated.emit(data);
          this.toastr.success(`¡Estatus de pago actualizado!`, '¡Éxito!');
          this.close();
          return;
        });
    }
  };
}
