import { Component } from '@angular/core';
import { PricingService } from '../../services/pricing.service';
import { PriceHistoryFilter, PriceHistoryItem } from '@interfaces';
import { ToastrService } from 'ngx-toastr';
import { CylinderType, ExportModules, ExportType } from '@enum';
import { FileService } from 'app/core/services/file.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-price-history',
  templateUrl: './price-history.component.html',
  styleUrl: './price-history.component.scss',
})
export class PriceHistoryComponent {
  filters: PriceHistoryFilter;
  pricesList: PriceHistoryItem[] = [];
  exportType = ExportType;
  cilynderType = CylinderType;
  itemCount: number = 0;
  currentDate: string;
  isLoading: boolean = false;
  exportModule: ExportModules = ExportModules.PRICING;

  messages = {
    emptyMessage: 'Sin resultados',
    totalMessage: 'Total',
    selectedMessage: 'Seleccionado',
  };

  constructor(
    private pricingService: PricingService,
    private toastr: ToastrService,
    private fileService: FileService,
    private datePipe: DatePipe
  ) {
    this.filters = {
      end_date: this.formatDate(new Date()),
      page: 0,
      start_date: this.formatDate(new Date()),
      product: CylinderType.all,
    };
    this.currentDate = this.formatDate(new Date());
  }

  columns = [
    {
      name: 'Fecha',
      prop: 'created_at',
      flexGrow: 1,
      resizeable: false,
    },
    {
      name: 'Cilindro',
      prop: 'product',
      flexGrow: 1,
      resizeable: false,
    },
    {
      name: 'Precio',
      prop: 'amount',
      flexGrow: 1,
      resizeable: false,
    },
    {
      name: 'Usuario',
      prop: 'user.name',
      flexGrow: 1,
      resizeable: false,
    },
  ];

  ngOnInit() {
    this.getPriceHistory();
  }

  public getPriceHistory = async (): Promise<any> => {
    this.isLoading = true;
    this.pricingService
      .filtered({ ...this.filters })
      .subscribe(({ data, code, count }) => {
        if (code === 500) {
          this.isLoading = false;
          return this.toastr.error(
            'No se pudo obtener el historial de precios',
            '¡Error!'
          );
        }
        this.pricesList = data;
        this.itemCount = count;
        this.isLoading = false;
        return;
      });
  };

  public resetFilters = (): void => {
    this.filters = {
      page: 0,
      end_date: this.formatDate(new Date()),
      start_date: this.formatDate(new Date()),
      product: CylinderType.all,
    };
  };

  public setPage = async (pageInfo: any): Promise<any> => {
    this.filters.page = pageInfo.offset;
    this.getPriceHistory();
  };

  formatDate = (date: Date | string | number | undefined): string => {
    return this.datePipe.transform(date, 'yyyy-MM-dd') ?? '';
  };
}
