import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'app/core/auth/auth.service';
import { ThemeService } from 'app/core/services/theme.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrl: './recover-password.component.scss',
})
export class RecoverPasswordComponent {
  submitted: boolean = false;
  recoverForm: FormGroup;
  isDarkMode: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService,
    private themeService: ThemeService
  ) {
    this.recoverForm = this.fb.group({
      number: ['', [Validators.required]],
    });
  }

  toggleTheme(isDarkMode: boolean) {
    this.themeService.setDarkTheme(isDarkMode);
  }

  ngOnInit() {
    this.themeService.darkTheme$.subscribe((isDark) => {
      this.isDarkMode = isDark;
    });

    const savedTheme = localStorage.getItem('$theme');
    if (savedTheme) {
      this.toggleTheme(savedTheme === 'dark');
    }
  }

  public onSubmit = async (): Promise<void> => {
    this.submitted = true;
    if (this.recoverForm.valid) {
      const employeeId: string = this.recoverForm.value.number;
      await this.authService.recoverPassword(employeeId).subscribe((data) => {
        if (data.status === 500) {
          this.submitted = false;
          return this.toastr.error(data.errors.email[0], '¡Error!');
        }
        return this.toastr.success(
          `¡Verifica tu correo electrónico, te enviamos un enlace para recuperar tu contraseña!`,
          '¡Enviado!'
        );
      });
      this.submitted = false;
    }
  };

  get numberErrors() {
    const numberControl = this.recoverForm.get('number');
    if (numberControl?.hasError('required')) {
      return 'Número de empleado requerido.';
    }
    return '';
  }
}
